// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["V77L68j3N"];

const variantClassNames = {V77L68j3N: "framer-v-pjuxny"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({background, height, id, width, ...props}) => { return {...props, bJ42C286o: background ?? props.bJ42C286o ?? "rgb(255, 255, 255)"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, bJ42C286o, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "V77L68j3N", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2C2Bg", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-pjuxny", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"V77L68j3N"} ref={ref} style={{backgroundColor: bJ42C286o, ...style}}><SVG className={"framer-1uht6uj"} layout={"position"} layoutDependency={layoutDependency} layoutId={"blecWI0pq-shape"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 65 64\"><path d=\"M 54.532 54.532 L 45.049 54.532 L 45.049 39.681 C 45.049 36.14 45.056 31.581 40.117 31.581 C 35.178 31.581 34.422 35.44 34.422 39.423 L 34.422 54.531 L 24.939 54.531 L 24.939 23.992 L 34.043 23.992 L 34.043 28.165 L 34.17 28.165 C 36.027 24.991 39.478 23.096 43.153 23.232 C 52.764 23.232 54.536 29.554 54.536 37.779 Z M 14.239 19.817 C 11.2 19.818 8.736 17.355 8.735 14.315 C 8.735 11.276 11.198 8.812 14.237 8.811 C 17.277 8.811 19.741 11.274 19.741 14.313 C 19.742 15.773 19.162 17.173 18.13 18.205 C 17.098 19.237 15.699 19.817 14.239 19.817 M 18.981 54.532 L 9.488 54.532 L 9.488 23.992 L 18.981 23.992 Z M 59.26 0.005 L 4.723 0.005 C 2.145 -0.024 0.031 2.04 -0 4.618 L -0 59.381 C 0.03 61.96 2.144 64.027 4.723 63.999 L 59.26 63.999 C 61.844 64.032 63.966 61.965 64.001 59.381 L 64.001 4.614 C 63.965 2.031 61.843 -0.033 59.26 0.001\" fill=\"var(--token-7224de54-28f4-407b-b654-683ef41f15c5, rgb(128, 131, 163)) /* {&quot;name&quot;:&quot;#8083A3&quot;} */\"></path></svg>"} svgContentId={3190254750} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2C2Bg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2C2Bg .framer-9xj1vy { display: block; }", ".framer-2C2Bg .framer-pjuxny { height: 64px; overflow: hidden; position: relative; width: 65px; }", ".framer-2C2Bg .framer-1uht6uj { flex: none; height: 64px; left: calc(49.230769230769255% - 65px / 2); position: absolute; top: calc(50.00000000000002% - 64px / 2); width: 65px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 65
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"bJ42C286o":"background"}
 * @framerImmutableVariables false
 */
const FramerR2XfjXlEO: React.ComponentType<Props> = withCSS(Component, css, "framer-2C2Bg") as typeof Component;
export default FramerR2XfjXlEO;

FramerR2XfjXlEO.displayName = "123te";

FramerR2XfjXlEO.defaultProps = {height: 64, width: 65};

addPropertyControls(FramerR2XfjXlEO, {bJ42C286o: {defaultValue: "rgb(255, 255, 255)", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerR2XfjXlEO, [])